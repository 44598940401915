<template>
  <menu-layout :value="2" class="online-exam">
    <gaudy-title label="专业课在线考试" />
    <div class="online-exam-top">
      <div class="top-one">
        <div class="top-one-top">
          <div class="top-one-top-left">考试进度</div>
          <div class="top-one-top-right">{{ Statistics.xueXiJinDu }}%</div>
        </div>
        <div>
          <el-progress
            :text-inside="true"
            define-back-color="rgba(186, 41, 38, 0.1)"
            color="#B50413"
            :show-text="false"
            :stroke-width="44"
            :percentage="Statistics.xueXiJinDu"
          ></el-progress>
        </div>
      </div>

      <div class="top-tow">
        <div class="top-tow-top">考试时间</div>
        <div class="top-tow-center">
          {{ Statistics.startTime }} ~ {{ Statistics.endTime }}
        </div>
        <div class="top-tow-bottom">进行中</div>
      </div>

      <div class="top-three">
        <div class="top-three-top">考试统计</div>
        <div class="top-three-bottom">
          <div class="top-three-bottom-card">
            <div class="value">{{ Statistics.keChengShu }}</div>
            <div class="text">
              {{ Statistics.isClass ? "学时数" : "课程数" }}
            </div>
          </div>
          <div class="top-three-bottom-card">
            <div class="value">{{ Statistics.weiWanCheng }}</div>
            <div class="text">未完成</div>
          </div>
          <div class="top-three-bottom-card">
            <div class="value">{{ Statistics.daiKaoShi }}</div>
            <div class="text">待考试</div>
          </div>
          <div class="top-three-bottom-card">
            <div class="value">{{ Statistics.kaoShiTongGuo }}</div>
            <div class="text">考试通过</div>
          </div>
        </div>
      </div>
    </div>
    <div class="online-exam-list">
      <radio-tab :list="selectList" @click="filterTypeSelect" />
      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="index" label="序号" align="center" width="100" />
        <el-table-column prop="courseName" label="课程名称" />
        <el-table-column prop="state" label="考试状态" width="90">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status == 1"
              :type="scope.row.ifQualified == 1 ? 'success' : 'warning'"
              size="small"
            >
              {{ scope.row.ifQualified == 1 ? "通过考试" : "待考试" }}
            </el-tag>
            <el-tag v-else type="danger" size="small">未学习</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="testScore"
          label="考试分数"
          align="center"
          width="80"
        />
        <el-table-column prop="action" align="center" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 1 && scope.row.ifQualified == 0"
              @click="handlerExamination(scope.row)"
              type="danger"
              plain
              size="mini"
              >开始考试</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--      <div class="pagination-body">-->
      <!--        <el-pagination-->
      <!--          background-->
      <!--          :page-size="100"-->
      <!--          layout="jumper, prev, pager, next"-->
      <!--          :total="1000"-->
      <!--        />-->
      <!--      </div>-->
    </div>
  </menu-layout>
</template>
<script>
import GaudyTitle from "@/components/gaudyTitle.vue";
import RadioTab from "@/components/radioTab.vue";
import MenuLayout from "@/components/MenuLayout.vue";
import {
  getCoursepPublicDemandAccount,
  getCoursepUserCourseList,
} from "@/api/specializedController";
import * as storage from "@/utils/storage";

export default {
  components: { MenuLayout, RadioTab, GaudyTitle },
  props: {},
  data() {
    return {
      selectList: [
        {
          label: "全部",
          value: 0,
        },
        {
          label: "已完成",
          value: 3,
        },
        {
          label: "未完成",
          value: 1,
        },
        {
          label: "待考试",
          value: 2,
        },
      ],
      tableData: [],
      filterType: 0,
      Statistics: {},
    };
  },
  computed: {
    yearId() {
      return storage.getStore("yearId");
    },
  },
  activated() {
    this.handleRefresh();
    // this.inspectRefresh(this.handleRefresh);
  },
  methods: {
    // 获取筛选项
    filterTypeSelect(data) {
      this.filterType = data.value;
      this.getDataList();
    },
    // 获取统计
    getStatistics() {
      const that = this;
      getCoursepPublicDemandAccount({
        trainingPlanId: this.yearId,
      }).then((res) => {
        that.Statistics = res.data;
      });
    },
    // 获取专业课列表信息
    getDataList() {
      const that = this;
      getCoursepUserCourseList({
        trainingPlanId: this.yearId,
        filterType: this.filterType,
        type: 0,
        ifExam: 1,
      }).then((res) => {
        that.tableData = res.data;
      });
    },
    handlerExamination(item) {
      this.keepAliveNavigation(`/examination?courseId=${item.courseId}`);
    },
    handleRefresh() {
      this.getStatistics();
      this.getDataList();
    },
  },
};
</script>
<style scoped lang="scss">
.online-exam-top {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 2fr;
  box-sizing: border-box;
  margin: 15px auto;

  .top-one {
    width: 100%;
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    /deep/ .el-progress-bar__outer {
      border-radius: 16px !important;
    }
    /deep/ .el-progress-bar__inner {
      border-radius: 16px !important;
    }

    .top-one-top {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 13px;

      .top-one-top-left {
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0em;
        font-feature-settings: "kern" on;
      }

      .top-one-top-right {
        font-family: Source Han Sans;
        font-size: 16px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0px;

        font-feature-settings: "kern" on;
        color: #ba2926;
      }
    }
  }

  .top-tow {
    width: 100%;
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;

    .top-tow-top {
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0em;
      font-feature-settings: "kern" on;
    }

    .top-tow-center {
      font-family: Source Han Sans;
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0em;
      white-space: nowrap;
      margin: 8px auto;
      font-feature-settings: "kern" on;
      /* 亮色模式/字体色/正文 */
      color: #505363;
    }

    .top-tow-bottom {
      font-family: Source Han Sans;
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0em;
      width: max-content;
      font-feature-settings: "kern" on;
      color: #b50413;
      box-sizing: border-box;
      border: 1px solid #b50413;
      padding: 2px 6px;
    }
  }

  .top-three {
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    padding: 10px;

    .top-three-top {
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0em;
      font-feature-settings: "kern" on;
    }

    .top-three-bottom {
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-top: 10px;
      .top-three-bottom-card {
        padding: 8px 16px;
        flex-grow: 1;
        align-self: stretch;
        background: #b50413;
        text-align: center;
        border-radius: 4px;
        .value {
          font-family: HarmonyOS Sans SC;
          font-size: 20px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0em;
          font-feature-settings: "kern" on;
          color: #ffffff;
        }
        .text {
          font-family: Source Han Sans;
          font-size: 12px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: 0em;

          font-variation-settings: "opsz" auto;
          font-feature-settings: "kern" on;
          color: #ffffff;
        }
      }
    }
  }
}
.online-exam-list {
  background-color: #ffffff;
  padding: 1px 24px 24px;
  border-radius: 4px;
  margin-bottom: 32px;
}
.pagination-body {
  padding: 16px 0;
  text-align: right;
  ::v-deep .el-pagination__jump {
    margin-left: 0;
    float: left;
  }
}
::v-deep .has-gutter th.el-table__cell {
  background-color: #f6f8fa;
}
</style>
